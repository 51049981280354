import './Contact.css'

export default function Contact() {
    return (
        <footer className='contact-section'>
            <h1>GET IN TOUCH</h1>
            <h2>hardwoodfootballclub@gmail.com</h2>
            <h3>© 2020 HardWood FC. All rights reserved.</h3>
            <h5> Website made by Trần Hoàng Long (tranlonguuu@gmail.com)</h5>
        </footer>
    )
}