import Introduction from "./Introduction"
import About from "./About"
import Players from "./Players"
import Contact from "./Contact"

export default function Home() {
    return (
        <div>
            <Introduction />
            <About />
            <Players />
            <Contact />
        </div>
    )
}