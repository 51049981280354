import './Introduction.css';
import gsap from "gsap";
import { useGSAP } from "@gsap/react";

export default function Introduction() {

    useGSAP(() => {
        gsap.fromTo(
            ".welcome-container",
            { 
                y: 125,
                opacity: 0
              },
              {
                y: 0,
                opacity: 1,
                stagger: 0.05,
                duration: 2.5,
                ease: 'power4.out',
              }
        );
    }, []);


    return (
        <section className="introduction-section">
            <div className='welcome-container'>
                <p className="welcome-text">WELCOME TO</p>
                <h1 className="team-title">HARDWOOD FC</h1>
            </div>
        </section>
    )
}