import './About.css';
import gsap from 'gsap';
import { useGSAP } from '@gsap/react';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import trophy from '../assessts/trophy.mp4'


gsap.registerPlugin(ScrollTrigger);

export default function About() {

    useGSAP(() => {
        gsap.fromTo(
            ".about-text", 
            { opacity: 0, y: 75 }, // Start state: invisible and moved down
            {
                opacity: 1,
                y: 0, // End state: fully visible and in original position
                duration: 1.5, // Animation duration
                ease: "power2.out",
                scrollTrigger: {
                    trigger: ".about-section",
                    start: "top 60%", // Trigger when the top of the section hits 80% of the viewport
                    scrub: false, // Optional: smooth scrolling effect
                },
            }
        );
    }, []);

    return (
        <section className="about-section">
            <div className="about-text">
                <h2>About</h2>
                <h1>HARDWOOD FC</h1>
                <p>
                    HardWood FC is a semi-professional soccer team based in Boston, founded in 2020 with the aim of providing a platform for local players to showcase their talent and passion for the game. Our team is dedicated to fostering a supportive and competitive environment for players to grow and excel in their soccer journey.
                </p>
            </div>
            <div className="about-video">
                <video src={trophy} autoPlay playsInline={true} loop muted />
            </div>
        </section>
    )
}