import './Players.css'

export default function Players() {

    const playerNames = ['Trần Hoàng Long','Thien','Thang Nguyen','Đăng Phùng','Viet Truong','Petr Tran','Khoi Le','TnT','MinhTr','Tin Tran']

    const nameElement = playerNames.map(name => <h3>{name}</h3>)

    return (
        <section className= "players-section">
            <h1 className='players-stars'>OUR STAR PLAYERS</h1>
            <h2>REAL WOOD</h2>
            {nameElement}
        </section>
    )
}