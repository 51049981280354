// src/components/Header.js
import React, { useState, useEffect } from 'react';
import { Link } from 'react-scroll';
import TeamLogo from '../assessts/lightX.PNG'; // Replace with your actual logo path
import './Header.css';

const Header = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    // Close the mobile menu when resizing to desktop
    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth > 768) {
                setIsMenuOpen(false);
            }
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    return (
        <header className="navbar-container">
            <div className="navbar">
                <div className="navbar-left">
                    <img src={TeamLogo} alt="Team Logo" className="team-logo" />
                    <div className="team-text">                    
                        <h1 className="team-name">HARDWOOD FC</h1>
                        <h3 className="team-slogan">Unleashing Potential, Achieving Greatness</h3>
                    </div>
                </div>
                <nav className="navbar-right">
                    {/* Desktop Menu Links */}
                    <ul className="desktop-menu">
                        <li><Link to="about-section" smooth={true} duration={500}>About</Link></li>
                        <li><Link to="players-section" smooth={true} duration={500}>Players</Link></li>
                        <li><Link to="contact-section" smooth={true} duration={500}>Contact</Link></li>
                    </ul>
                    
                    {/* Hamburger Icon for Mobile */}
                    <div className="hamburger" onClick={toggleMenu}>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                    
                    {/* Mobile Menu Links */}
                    <ul className={`menu-links ${isMenuOpen ? 'open' : ''}`}>
                        <li><Link to="about-section" smooth={true} duration={500} onClick={toggleMenu}>About</Link></li>
                        <li><Link to="players-section" smooth={true} duration={500} onClick={toggleMenu}>Players</Link></li>
                        <li><Link to="contact-section" smooth={true} duration={500} onClick={toggleMenu}>Contact</Link></li>
                    </ul>
                </nav>
            </div>
        </header>
    );
};

export default Header;